/* /**
* Template Name: Mentor
* Template URL: https://bootstrapmade.com/mentor-free-education-bootstrap-theme/
* Updated: Jun 29 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/





/* Fonts */






:root {
  --default-font: "Open Sans",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root { 
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #37423b; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #5fcf80; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #272828;  /* The default color of the main navmenu links */
  --nav-hover-color: #5fcf80; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #272828; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #5fcf80; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f9f9f9;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Pulsating Play Button
------------------------------*/
.pulsating-play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 75%) 52%);
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.pulsating-play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-play-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.pulsating-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.pulsating-play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.pulsating-play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-play-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 15px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 60px;
  margin-right: 8px;
}

.header .logo h1 {
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--accent-color);
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: #78AC71;
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
/* Desktop Navigation */



@media (min-width: 1200px) {


  
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  /* General link styles */
.navmenu a,
.navmenu a:focus {
  color: var(--nav-color); /* Default color for all links */
  padding: 18px 15px;
  font-size: 16px;
  font-family: var(--nav-font);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

/* Ensure that no specific styles override the default color */
.navmenu .dropdown .dropdown-toggle {
  color: var(--nav-color); /* Apply default color to dropdown links */
}

/* Add hover and focus styles if needed */
.navmenu a:hover,
.navmenu a:focus {
  color: #060606 !important; /* Example color for hover/focus state */
}

/* Additional styles for specific dropdown states */
.dropdown-active .dropdown-toggle {
  color: #78AC71 !important; /* Color for active dropdown link */
}
.nav-link:hover,
.nav-link:focus {
  color: green !important; /* Change to green when hovering or focusing */
}














  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: #78AC71; /* Highlight color for active and hovered links */
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}


/* Mobile Navigation */
@media (max-width: 1199px) {

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color); /* Default color for all links */
    padding: 18px 15px;
    font-size: 16px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  /* Ensure that no specific styles override the default color */
  .navmenu .dropdown .dropdown-toggle {
    color: var(--nav-color); /* Apply default color to dropdown links */
  }
  
  /* Add hover and focus styles if needed */
  .navmenu a:hover,
  .navmenu a:focus {
    color: #78AC71 !important; /* Example color for hover/focus state */
  }
  
  /* Additional styles for specific dropdown states */
  .dropdown-active .dropdown-toggle {
    color: #78AC71 !important; /* Color for active dropdown link */
  }
  .nav-link:hover,
  .nav-link:focus {
    color: green !important; /* Change to green when hovering or focusing */
  }




  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 10000;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 300px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: right 0.3s ease-in-out;
  }

  .navmenu ul {
    position: relative;
    top: 60px;
    padding: 10px 0;
    margin: 0;
    background-color: var(--nav-mobile-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    overflow-y: auto;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navmenu {
    right: 0;
  }

  .navmenu .dropdown .dropdown-menu {
    display: none;
  }

  .navmenu .dropdown.dropdown-active .dropdown-menu {
    display: block;
  }

  .navmenu ul {
    display: none;
  }

  .navmenu.navmenu-open > ul {
    display: block;
  }
}












/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: var(--background-color);
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-color: var(--accent-color) transparent var(--accent-color) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  --default-color: var(--contrast-color);
  --background-color: var(--accent-color);
  --heading-color: white;
  color: white;
  background-color: #78AC71;
  position: relative;
}

.page-title .heading {
  padding: 80px 0;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.page-title nav {
  background-color: color-mix(in srgb, var(--accent-color) 90%, black 5%);
  padding: 20px 0;
}

.page-title nav a {
  color: var(--default-color);
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol li+li {
  padding-left: 10px;
}

.page-title nav ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 900px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 66px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  position: relative;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: var(--accent-color);
  margin: 4px 10px;
}

.section-title p {
  color: var(--heading-color);
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  font-family: var(--nav-font);
  text-transform: uppercase;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 80vh;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-color);
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 60%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 10px 0 0 0;
  font-size: 24px;
}

.hero .btn-get-started {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 50px;
  transition: 0.4s;
  margin-top: 30px;
  border: 2px solid var(--default-color);
  color: var(--default-color);
}

.hero .btn-get-started:hover {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content h2 {
  font-size: 2rem;
  font-weight: 700;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: var(--accent-color);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 10px 24px 12px 24px;
  border-radius: 50px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.about .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.about .content .read-more:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  padding-right: 19px;
}

.about .content .read-more:hover i {
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Counts Section
--------------------------------------------------------------*/
.counts {
  padding: 25px 0;
}

.counts .stats-item {
  padding: 30px;
  width: 100%;
}

.counts .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--accent-color);
  font-weight: 700;
}

.counts .stats-item p {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 15px;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Why Us Section
--------------------------------------------------------------*/
.why-us .why-box {
  color: var(--contrast-color);
  background: var(--accent-color);
  padding: 30px;
}

.why-us .why-box h3 {
  color: var(--contrast-color);
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.why-us .why-box p {
  margin-bottom: 30px;
}

.why-us .why-box .more-btn {
  display: inline-block;
  background: color-mix(in srgb, var(--contrast-color), transparent 85%);
  padding: 8px 40px 10px 40px;
  color: var(--contrast-color);
  transition: all ease-in-out 0.4s;
  border-radius: 50px;
}

.why-us .why-box .more-btn i {
  font-size: 14px;
}

.why-us .why-box .more-btn:hover {
  color: var(--accent-color);
  background: var(--surface-color);
}

.why-us .icon-box {
  background-color: var(--surface-color);
  text-align: center;
  padding: 40px 30px;
  width: 100%;
  height: 100%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.why-us .icon-box i {
  color: var(--accent-color);
  font-size: 32px;
  margin-bottom: 30px;
  background: color-mix(in srgb, var(--accent-color), transparent 95%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  transition: 0.3s;
}

.why-us .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px 0;
}

.why-us .icon-box p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.why-us .icon-box:hover i {
  color: var(--contrast-color);
  background: var(--accent-color);
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding: 10px 0;
}

.features .features-item {
  background-color: var(--surface-color);
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 0.3s;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  position: relative;
}

.features .features-item i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 0;
}

.features .features-item h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .features-item h3 a {
  color: var(--heading-color);
  transition: 0.3s;
}

.features .features-item:hover {
  border-color: var(--accent-color);
}

.features .features-item:hover h3 a {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Courses Section
--------------------------------------------------------------*/
.courses .course-item {
  background-color: var(--surface-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  border-radius: 5px;
}

.courses .course-content {
  padding: 15px;
}

.courses .course-content h3 {
  font-weight: 700;
  font-size: 20px;
}

.courses .course-content h3 a {
  color: var(--heading-color);
  transition: 0.3s;
}

.courses .course-content h3 a:hover {
  color: var(--accent-color);
}

.courses .course-content .category {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0;
  border-radius: 5px;
}

.courses .course-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.courses .course-content .description {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.courses .trainer {
  padding-top: 15px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.courses .trainer .trainer-profile img {
  max-width: 50px;
  border-radius: 50px;
}

.courses .trainer .trainer-profile .trainer-link {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.courses .trainer .trainer-profile .trainer-link:hover {
  color: var(--accent-color);
}

.courses .trainer .trainer-rank {
  font-size: 18px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.courses .trainer .trainer-rank .user-icon {
  font-size: 22px;
}

/*--------------------------------------------------------------
# Trainers Index Section
--------------------------------------------------------------*/
.trainers-index .member {
  background-color: var(--surface-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  text-align: center;
  margin-bottom: 20px;
}

.trainers-index .member img {
  margin: -1px -1px 30px -1px;
}

.trainers-index .member .member-content {
  padding: 0 20px 30px 20px;
}

.trainers-index .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.trainers-index .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.trainers-index .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.trainers-index .member .social {
  margin-top: 15px;
}

.trainers-index .member .social a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  transition: 0.3s;
}

.trainers-index .member .social a:hover {
  color: var(--accent-color);
}

.trainers-index .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# About Us Section
--------------------------------------------------------------*/
.about-us .content h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #78AC71;
}

.about-us .content ul {
  list-style: none;
  padding: 0;
}

.about-us .content ul li {
  padding-bottom: 10px;
}

.about-us .content ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: var(--accent-color);
}

.about-us .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  background-color: var(--surface-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  position: relative;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid var(--background-color);
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 60%);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--background-color);
  opacity: 1;
  border: 1px solid var(--accent-color);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Courses Course Details Section
--------------------------------------------------------------*/
.courses-course-details {
  padding-bottom: 20px;
}

.courses-course-details h3 {
  font-size: 24px;
  margin: 30px 0 15px 0;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
}

.courses-course-details h3:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 90%);
  bottom: 0;
  left: 0;
}

.courses-course-details h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 1px;
  background: var(--accent-color);
  bottom: 0;
  left: 0;
}

.courses-course-details .course-info {
  background: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 10px 15px;
  margin-bottom: 15px;
}

.courses-course-details .course-info h5 {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  font-family: var(--nav-font);
}

.courses-course-details .course-info p {
  margin: 0;
  font-weight: 600;
}

.courses-course-details .course-info a {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Tabs Section
--------------------------------------------------------------*/
.tabs {
  padding-top: 30;
}

.tabs .nav-tabs {
  border: 0;
}

.tabs .nav-link {
  background-color: var(--background-color);
  border: 0;
  padding: 12px 15px;
  transition: 0.3s;
  color: var(--default-color);
  border-radius: 0;
  border-right: 2px solid color-mix(in srgb, var(--default-color), transparent 90%);
  font-weight: 600;
  font-size: 15px;
}

.tabs .nav-link:hover {
  color: var(--accent-color);
}

.tabs .nav-link.active {
  background-color: var(--surface-color);
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.tabs .tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.tabs .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.tabs .details p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.tabs .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .tabs .nav-link {
    border: 0;
    padding: 15px;
  }

  .tabs .nav-link.active {
    color: var(--accent-color);
    background: var(--accent-color);
  }
}

/*--------------------------------------------------------------
# Trainers Section
--------------------------------------------------------------*/
.trainers .member {
  position: relative;
}

.trainers .member .member-img {
  margin: 0 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1024px) {
  .trainers .member .member-img {
    margin: 0 60px;
  }
}

.trainers .member .member-img img {
  position: relative;
  z-index: 1;
}

.trainers .member .member-img .social {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding-bottom: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.trainers .member .member-img .social a {
  transition: 0.3s;
  color: var(--contrast-color);
  font-size: 20px;
  margin: 0 8px;
}

.trainers .member .member-img .social a:hover {
  color: var(--accent-color);
}

.trainers .member .member-info {
  margin-top: 30px;
}

.trainers .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 6px;
  font-size: 18px;
}

.trainers .member .member-info span {
  font-style: italic;
  display: block;
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 10px;
}

.trainers .member .member-info p {
  margin-bottom: 0;
  font-size: 14px;
}

.trainers .member:hover .member-img .social {
  padding-bottom: 0;
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Events Section
--------------------------------------------------------------*/
.events .card {
  background-color: var(--background-color);
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
}

.events .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}

.events .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.events .card-body {
  z-index: 10;
  background: var(--surface-color);
  border-top: 4px solid var(--surface-color);
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
}

.events .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.events .card-title a {
  color: var(--default-color);
  transition: 0.3s;
}

.events .card-text {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.events .card:hover img {
  transform: scale(1.1);
}

.events .card:hover .card-body {
  border-color: var(--accent-color);
}

.events .card:hover .card-body .card-title a {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
  background-color: var(--surface-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing .pricing-item h3 {
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  background: color-mix(in srgb, var(--default-color), transparent 95%);
}

.pricing .pricing-item h4 {
  font-size: 36px;
  font-weight: 600;
  font-family: var(--heading-font);
}

.pricing .pricing-item h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .pricing-item h4 span {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 16px;
  font-weight: 300;
}

.pricing .pricing-item ul {
  padding: 15px 0;
  list-style: none;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .pricing-item ul li {
  padding-bottom: 16px;
}

.pricing .pricing-item ul i {
  color: var(--accent-color);
  font-size: 18px;
  padding-right: 4px;
}

.pricing .pricing-item ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  text-decoration: line-through;
}

.pricing .btn-wrap {
  background: color-mix(in srgb, var(--default-color), transparent 95%);
  margin: 0 -20px -20px -20px;
  padding: 20px 15px;
  text-align: center;
}

.pricing .btn-buy {
  background: var(--accent-color);
  color: var(--contrast-color);
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--heading-font);
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.pricing .featured h3 {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.pricing .advanced {
  background: var(--accent-color);
  color: var(--contrast-color);
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact {
  padding-top: 8px;
  padding-bottom: 50px;
}

.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  color: var(--contrast-color);
  background: #78AC71;
  font-size: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border: 2px solid color-mix(in srgb, #090C10, transparent 70%);


}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
  color: var(--contrast-color);
  background: #78AC71;
  border: 0;
  padding: 10px 30px 12px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}



.contact.transparent-green-bg {
  background-color: rgba(0, 128, 0, 0.2); /* Adjust the transparency as needed */
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}






ul {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

ul > li > ul {
  list-style-type: disc; /* Add bullets to nested lists */
  padding-left: 20px; /* Add padding to nested lists */
}

.bi-check-circle {
  margin-right: 8px; /* Space between icon and text */
  color: green; /* Color the icon */
}

.policies p{
  font-size: 2rem;
  font-weight: 500;
  
}



.policies .policies-text a{
  color:black;

}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact .contact-us {
  text-align: center;
  margin-bottom: 60px;
  color: #78AC71;
  position: relative; /* Ensures positioning of the pseudo-element */
  animation: fadeInUp 1s ease-out; /* Add animation here */
  font-weight: 700;
}

.contact .contact-us::after {
  content: "";
  display: block;
  width: 15%; /* Adjust width for the short border */
  height: 2px; /* Adjust thickness of the border */
  background-color: #78AC71; /* Border color */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); /* Centers the border horizontally */
}


/* BoardCommittes */
.boardcommittee  h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #78AC71;
  margin-top: 21px;
}

.boardcommittee  ul {
  list-style: none;
  padding: 0;
}

.boardcommittee  ul li {
  padding-bottom: 10px;
}

.boardcommittee  ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: var(--accent-color);
}

.boardcommittee  p:last-child {
  margin-bottom: 0;
}


/* Footer Section */
/* .foot{
  background-color: #78AC71;
} */




.footer {
  background-color: #78AC71;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.footer-text {
  margin-bottom: 0;
  font-size: 18px;
}

.sitename {
  padding: 0 5px;
  font-weight: bold;
}

.credits {
  /* margin-top: 10px; */
  font-size: 12px;
}

@media (min-width: 1200px) {
  .footer-text {
    font-size: 18px;
  }

  .credits {
    font-size: 16px;
  }
}
/* for large lappy ----- React project contact form*/
/* padding-top:68px ---- for small lappy */
@media (min-width: 1200px){
  .contact{
    padding-top:30px; 
  }
}



/* contact button on nav */
/* Default styling for the NavLink */
.header .btn-getstarted {
  color: white; /* or your default color */
}

/* Styling for the active NavLink */
 .header .btn-getstarted.active {
  color: white !important; /* Change to white when active */
}

.corporateanno p{
  font-size: 2rem;
  font-weight: 500;
  
}



.corporateanno .policies-text a{
  color:black;

}



.hero {
  position: relative;
  overflow: hidden; /* Ensure the overlay does not exceed the boundaries of the hero section */
}

.hero img {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(120, 172, 113, 0.5); /* Transparent color with 50% opacity */
  z-index: 1; /* Ensure it is above the image but below the text */
}

.container {
  position: relative;
  z-index: 2; /* Ensure the text is above the overlay */
  padding: 20px; /* Adjust padding if necessary */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero img {
    height: auto; /* Adjust image height for smaller screens if needed */
  }
  .hero {
    min-height: 0vh;
  }
}



.reports-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.report-link {
  text-decoration: none;
  color: black;
}

.report-item {
  display: flex;
  align-items: center;
  border: 1px solid #78AC71;
  padding: 10px;
  width: 250px;
  background-color: #f9f9f9;
  margin: 5px;
  justify-content: center;
  border-radius:2%;
  color: black;
}

.report-item:hover {
  background-color: #eaeaea;
}

.pdf-icon {
  margin-right: 10px;
}



.kyc p{
  font-size: 2rem;
  font-weight: 500;
  
}



.kyc .policies-text a{
  color:black;

}
/* main {
  flex: 1; 
} */


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1; /* Ensures that the main content takes up the remaining space */
}


